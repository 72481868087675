<div
  class="table-container"
  *ngIf="tableConfig"
  [class.table-row]="emptyRow"
  [class.table-empty]="tableConfig.isEmpty()"
  [class.loading]="isLoading">
  <div class="table-wrapper">
  <table
    matSort
    matSortDisableClear
    mat-table
    class="table"
    [ngClass]="additionalClass"
    [dataSource]="localPagination ? dataSource : tableConfig.dataSource"
    [matSortActive]="tableConfig.sortKey ?? tableConfig.dataKey[0]"
    [matSortDirection]="tableConfig.sortOrder"
    (matSortChange)="sortChange.emit($event)">
    <tr
      mat-header-row
      class="table-header"
      *matHeaderRowDef="tableConfig.dataKey"></tr>

    <tr
      mat-row
      class="table-row"
      *matRowDef="let row; columns: tableConfig.dataKey; let rowIndex = index"
      [ngClass]="row.isSelected && 'table-row-selected'"
      [attr.data-status]="row['status']"
      (click)="selectData.emit(row)"
      (mouseleave)="mouseOverChange.emit(null)"
      (mouseover)="mouseOverChange.emit(row)"
      [ngStyle]="{ 'background-color': row.backgroundColor }"></tr>

    <ng-container
      *ngFor="let tableKey of tableConfig.dataKey; let index = index"
      [matColumnDef]="tableKey">
      <th
        mat-header-cell
        *matHeaderCellDef
        [disableClear]="true"
        [disabled]="!tableConfig.dataSortKey[index]"
        [mat-sort-header]="tableConfig.dataSortKey[index] ?? ''"
        [ngSwitch]="tableKey">
        <span class="table-header" *ngSwitchDefault>
          {{ tableConfig.dataLabel[index] }}
        </span>

        <ng-container
          *ngSwitchCase="'selection'"
          [ngTemplateOutlet]="selectionTemplate">
        </ng-container>
      </th>

      <td
        mat-cell
        *matCellDef="let data; let i = index; let rowIndex = index"
        (click)="disableClick($event, tableKey)">
        <div class="table-row-cell" [ngSwitch]="tableConfig.dataType[tableKey]">
          <ng-container *ngSwitchDefault>
            {{ getPropertyValue(data, tableKey) || '-'}}
          </ng-container>

          <ng-container *ngSwitchCase="'boolean'">
            {{ getPropertyValue(data, tableKey) | boolean }}
          </ng-container>

          <ng-container *ngSwitchCase="'bytesize'">
            {{ getPropertyValue(data, tableKey) | bytesize }}
          </ng-container>

          <ng-container *ngSwitchCase="'date'">
            {{ getPropertyValue(data, tableKey) | date: "M/d/yyyy hh:mma" }}
          </ng-container>

          <ng-container *ngSwitchCase="'M,ddYY'">
            {{ getPropertyValue(data, tableKey) | date }}
          </ng-container>

          <ng-container *ngSwitchCase="'number'">
            {{ getPropertyValue(data, tableKey) }}
          </ng-container>

          <ng-container *ngSwitchCase="'title'">
            {{ getPropertyValue(data, tableKey) | titlecase }}
          </ng-container>

          <ng-container *ngSwitchCase="'M/d/yyyy'">
            {{ getPropertyValue(data, tableKey) | date: "M/d/yyyy" }}
          </ng-container>

          <ng-container *ngSwitchCase="'currency'">
            {{ getPropertyValue(data, tableKey) | currency }}
          </ng-container>

          <ng-container
            *ngSwitchCase="'custom'"
            [ngTemplateOutlet]="customTemplate"
            [ngTemplateOutletContext]="{
              data: data,
              key: tableKey,
              index: rowIndex
            }">
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container *ngIf="emptyRow && !isLoading">
      <tr
        mat-footer-row
        [hidden]="tableConfig.dataSource.length"
        *matFooterRowDef="['nodata']"></tr>
    </ng-container>

    <ng-container matColumnDef="nodata" *ngIf="!isLoading">
      <td
        class="border-empty pl-10"
        mat-footer-row
        *matFooterCellDef
        [colSpan]="tableConfig.dataKey.length">
        <span [class.visibility]="isLoading ? 'visible' : 'hidden'">
          No Data Available
        </span>
      </td>
    </ng-container>
  </table>
  </div>
  <ng-container *ngIf="!tableConfig.isEmpty()">
    <div class="table-pagination" *ngIf="httpPagination || localPagination">
      <mat-paginator
        *ngIf="httpPagination"
        [length]="tableConfig.dataTotal"
        [pageIndex]="tableConfig.pageIndex"
        [pageSize]="tableConfig.pageSize"
        [pageSizeOptions]="[2, 5, 10, 25, 50, 100]"
        (page)="pageChange.emit($event)"></mat-paginator>

      <mat-paginator
        #paginator
        *ngIf="localPagination"
        [pageSizeOptions]="[2, 5, 10, 25, 50, 100]"></mat-paginator>
    </div>
  </ng-container>

  <div *ngIf="isLoading" class="spinner-container">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"></mat-progress-spinner>
  </div>

  <app-empty-state
    [category]="category"
    *ngIf="!isLoading && tableConfig.isEmpty() && !emptyRow"
    [icon]="emptyState.icon ? emptyState.icon : defaultIcon"
    [subtitle]="emptyState.subtitle"
    [title]="!category ? emptyState.title : 'Result not found'">
    <ng-template #subTemplate *ngIf="emptyState.subtitle">
      <p class="mt-5">
        <a class="fw-600 roam-text-link">Select one association</a>&nbsp;to get started
      </p>
    </ng-template>
  </app-empty-state>
</div>
